/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <nav class="breadcrumb-show d-none d-md-block">
      <div class="container">
        <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">Login</a>
        <p class="breadcrumb-show__description">
          Print your desired look on ready-made shirts with various sizes and colors.
        </p>
      </div>
    </nav>
    <nav aria-label="breadcrumb " class="d-none d-md-block">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Login</li>
        </ol>
      </div>
    </nav>
    <section class="content-wrapper">
      <div class="login">
        <div class="container">
          <div class="login__content">
            <div class="login__content--item">
              <button class="btn d-flex align-items-center" @click="handleSignInFacebook">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.3235 35V19.9981H13V14.829H16.3235V11.7256C16.3235 7.50884 18.1991 5 23.5314 5H27.9695V10.171H25.1957C23.1199 10.171 22.9827 10.8934 22.9827 12.2415L22.9736 14.829H28L27.4117 19.9981H22.9736V35H16.3235Z"
                    fill="#3B5998" />
                </svg>
                Sign in with Facebook
              </button>
            </div>
            <div class="login__content--item">
              <button class="btn d-flex align-items-center" @click="handleSignInTwitter">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M31.4659 10.9524C32.7373 10.1724 33.7128 8.93558 34.1706 7.46223C32.9803 8.18626 31.6649 8.70988 30.2615 8.99336C29.1416 7.76556 27.5409 7 25.7695 7C22.371 7 19.617 9.82573 19.617 13.3105C19.617 13.8052 19.6681 14.2873 19.7737 14.7477C14.6602 14.4841 10.1259 11.9744 7.0884 8.15376C6.55837 9.08905 6.2555 10.1724 6.2555 11.328C6.2555 13.5163 7.34196 15.4483 8.99366 16.5822C7.98468 16.5497 7.03557 16.2626 6.20444 15.7932V15.8708C6.20444 18.9294 8.32453 21.4807 11.1437 22.0585C10.626 22.2066 10.0836 22.2806 9.52016 22.2806C9.12397 22.2806 8.73657 22.2427 8.36327 22.1686C9.1451 24.6748 11.4184 26.502 14.1125 26.5508C12.0048 28.2444 9.35112 29.2519 6.46857 29.2519C5.972 29.2519 5.48072 29.2248 5 29.1652C7.72407 30.9546 10.9588 32 14.4348 32C25.7572 32 31.9449 22.3835 31.9449 14.0436C31.9449 13.7691 31.9414 13.4965 31.929 13.2274C33.1317 12.3373 34.1777 11.225 35 9.95934C33.8959 10.4613 32.7091 10.8007 31.4659 10.9524Z"
                    fill="#08A0E9" />
                </svg>

                Sign in with Twitter
              </button>
            </div>
            <div class="login__content--item">
              <button class="btn d-flex align-items-center" @click="handleSignInGoogle">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M32.9998 19.7777C32.9998 18.7499 32.9147 17.9999 32.7305 17.2222H20.7549V21.861H27.7843C27.6426 23.0138 26.8773 24.7499 25.1766 25.9165L25.1528 26.0718L28.9392 28.9465L29.2016 28.9722C31.6108 26.7916 32.9998 23.5832 32.9998 19.7777Z"
                    fill="#4285F4" />
                  <path
                    d="M20.7549 31.9999C24.1987 31.9999 27.0899 30.8888 29.2016 28.9722L25.1767 25.9165C24.0996 26.6526 22.654 27.1665 20.7549 27.1665C17.3819 27.1665 14.5192 24.986 13.4986 21.9722L13.3491 21.9846L9.41184 24.9707L9.36035 25.111C11.4578 29.1943 15.7662 31.9999 20.7549 31.9999Z"
                    fill="#34A853" />
                  <path
                    d="M13.4988 21.9723C13.2295 21.1945 13.0737 20.3612 13.0737 19.5001C13.0737 18.6389 13.2295 17.8056 13.4846 17.0279L13.4775 16.8622L9.49094 13.8281L9.36051 13.8889C8.49604 15.5834 8 17.4862 8 19.5001C8 21.514 8.49604 23.4167 9.36051 25.1111L13.4988 21.9723Z"
                    fill="#FBBC05" />
                  <path
                    d="M20.7549 11.8333C23.15 11.8333 24.7656 12.8472 25.6869 13.6945L29.2866 10.25C27.0758 8.23612 24.1987 7 20.7549 7C15.7662 7 11.4578 9.80553 9.36035 13.8888L13.4845 17.0278C14.5192 14.0139 17.3819 11.8333 20.7549 11.8333Z"
                    fill="#EB4335" />
                </svg>

                Sign in with Google
              </button>
            </div>
       <!--      <div class="login__content--item">
              <button class="btn d-flex align-items-center" @click="handleSignInApple">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M30 24.8747C29.4553 26.0713 29.1926 26.604 28.4891 27.6641C27.5079 29.1406 26.1229 30.9823 24.4068 30.9958C22.8836 31.0107 22.4922 30.0155 20.423 30.0277C18.3565 30.0385 17.9241 31.0134 16.3995 30.9998C14.6848 30.985 13.3737 29.3232 12.3911 27.8453C9.64582 23.7159 9.35706 18.8671 11.0513 16.2886C12.2556 14.4578 14.1538 13.3869 15.9397 13.3869C17.7557 13.3869 18.8985 14.3713 20.4025 14.3713C21.86 14.3713 22.7481 13.3842 24.8502 13.3842C26.4404 13.3842 28.1237 14.2401 29.3226 15.7166C25.3922 17.8462 26.0299 23.39 30 24.8747ZM23.2576 11.7333C24.0198 10.7651 24.6001 9.39676 24.3907 8C23.1426 8.08383 21.6824 8.86942 20.8312 9.89164C20.0566 10.8179 19.4188 12.1957 19.6665 13.5343C21.0323 13.5762 22.4405 12.7717 23.2576 11.7333Z"
                    fill="#2A2D46" />
                </svg>

                Sign in with Apple
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

import firebaseConfig from '../firebaseConfig';

import { getAuth, signInWithPopup, signOut, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider, FacebookAuthProvider } from "firebase/auth";

firebaseConfig

const providerGoogle = new GoogleAuthProvider();
const providerTwitter = new TwitterAuthProvider();
const providerFacebook = new FacebookAuthProvider();
// OAuthProvider
const auth = getAuth();

export default {
  data() {
    return {
    }
  },
  mounted() {
    if (this.currentUser.id) {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions({
      authenticateWithServer: 'authentication/authenticateWithServer',
    }),
    handleSignInFacebook() {
      providerFacebook.addScope('email');
      signInWithPopup(auth, providerFacebook)
        .then(async (result) => {
          this.user = result.user.displayName;
          this.isSignedIn = true;
          const params = {
            deviceId: navigator.userAgent,
            email: result.user.providerData[0].email,
            displayName: result.user.providerData[0].displayName,
            ssoToken: result._tokenResponse.idToken,
            ssoType: result.providerId,
            ssoId: result.user.uid
          }
          const token = await this.authenticateWithServer(params);
          setTimeout(() => {
            this.$router.push('/');
          }, 1000);
        }).catch((error) => {
          console.log(error);
        });
    },
    handleSignInTwitter() {
      signInWithPopup(auth, providerTwitter)
        .then(async (result) => {
          this.user = result.user.displayName;
          this.isSignedIn = true;
          const params = {
            deviceId: navigator.userAgent,
            email: result.user.providerData[0].email,
            displayName: result.user.providerData[0].displayName,
            ssoToken: result._tokenResponse.idToken,
            ssoType: result.providerId,
            ssoId: result.user.uid
          }
          const token = await this.authenticateWithServer(params);
          setTimeout(() => {
            this.$router.push('/');
          }, 1000);
        }).catch((error) => {
          console.log(error);
        });
    },
    handleSignInGoogle() {
      providerGoogle.addScope('email');
      providerGoogle.addScope('profile');
      signInWithPopup(auth, providerGoogle)
        .then(async (result) => {
          this.user = result.user.displayName;
          this.isSignedIn = true;
          const params = {
            deviceId: navigator.userAgent,
            email: result.user.providerData[0].email,
            displayName: result.user.providerData[0].displayName,
            ssoToken: result._tokenResponse.idToken,
            ssoType: result.providerId,
            ssoId: result.user.uid
          }
          const token = await this.authenticateWithServer(params);
          setTimeout(() => {
            this.$router.push('/');
          }, 1000);
         
        }).catch((error) => {
          console.log(error);
        });
    },
    handleSignInApple() {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.addScope('profile');
      signInWithPopup(auth, provider)
        .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

      });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser',
    }),
  }
}
</script>
